import React, { useEffect, useMemo, useRef, useState } from "react";

import { FilterData, FilterDataItem } from "./store/model";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  reset,
  bondValuationSelector,
  setBondFilterMetric,
  setTableData,
} from "./store/slice";
import DropGroup from "application/components/DropGroup";
import {
  Title,
  Container,
  DropContainer,
  FiltersContainer,
  TitleWrapper,
  CompareButton,
  TableInfoWrapper,
  BottomLogo,
  UnderContentInfo,
  ArrowBackContainer,
  ArrowBack,
  BottomMfruLogo,
  LogoWrapper,
  SubTitle,
  OpenChartIcon,
  ItemDescriptionWrapper,
} from "./styles";
import { Loader } from "../../features/chart/styles";
import SmallLoader from "../../application/components/SmallLoader";
import MainContainer from "../../application/components/MainContainer";
import {
  Data,
  NoData,
} from "../../application/components/StickyHeaderTable/types";
import { find, propEq } from "ramda";
import logoBlueSource from "../../application/assets/images/logo-blue.svg";
import { applicationConfig } from "../../application/config/applicationConfig";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";
import LastUpdateDate from "../../application/components/LastUpdateDate";
import NotifyPopUpTable from "../../application/components/NotifyPopUpTable";
import { useMediaQuery, useTheme } from "@mui/material";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";
import { formatDate } from "application/helpers/dateFormatter";
import OptionalTooltip from "application/components/OptionalTooltip";
import { useLocation, useSearchParams } from "react-router-dom";
import BondStickyHeaderTable from "application/components/BondStickyHeaderTable";
import BloombergInfo from "application/components/BloombergInfo";
import BondChartModal from "application/components/BondChartModal";
import BondValuationSummaryActions from "./store/actions";
import ItemDescription from "features/chart/components/ItemDescription";

const ROUND_DECIMALS_TO_ZERO = ["market_value", "number_of_issues"];
const INDECES_WITH_NULLABLE_DATES = ["average_credit_quality"];

function getMetricByCode(code: string, data: FilterData) {
  for (const key in data) {
    const array = data[key];
    const foundObject = array.find((obj) => obj.code === code);
    if (foundObject) {
      return foundObject;
    }
  }
  return null;
}

const OpenChartAction = ({
  onClickHandler,
}: {
  onClickHandler: () => void;
}) => {
  return <OpenChartIcon onClick={onClickHandler} />;
};

const BondValuationSummary = () => {
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [compare, setCompare] = React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const [date, setDate] = useState<string>("");
  const buttonSectionRef = useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] = useState<number>(0);
  const [isBloombergVisible, setIsBloombergVisible] =
    React.useState<boolean>(false);

  const toggleBloombergVisible = () => {
    setIsBloombergVisible(!isBloombergVisible);
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { filters, tableData, metrics } = useAppSelector(bondValuationSelector);

  const [selectedIndex, setSelectedIndex] = useState<{
    code: string;
    name: string;
    description?: string;
  }>();

  const openChart = (currIndex: {
    code: string;
    name: string;
    description?: string;
  }) => setSelectedIndex(currIndex);
  const closeChart = () => setSelectedIndex(undefined);

  const tableRowsData = useMemo(() => {
    if (tableData?.bondSummaryData?.length) {
      return tableData?.bondSummaryData.map((d: any) => {
        const indexObj = {
          code: d.indexCode,
          name: d.indexName,
          description: d.description,
        };

        return {
          ...d,
          action: (
            <OpenChartAction
              onClickHandler={() => {
                openChart(indexObj);
              }}
            />
          ),
        };
      });
    }

    return tableData;
  }, [tableData]);

  const loading = requestData.getExpectedBondTableData.loading;

  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    Promise.all([dispatch(BondValuationSummaryActions.getBondMetrics())]).then(
      (resArr) => {
        const queryParams = new URLSearchParams(location.search);
        const metricFromQueryParams = queryParams.get("metric");

        const metricRes = resArr[0].payload as FilterData;
        const metricKeys = Object.keys(metricRes);

        const selectedMetricFromRedirect =
          metricFromQueryParams !== null
            ? getMetricByCode(metricFromQueryParams, metricRes)
            : undefined;

        const initialMetric =
          selectedMetricFromRedirect ?? metricRes[metricKeys[0]][0];

        handleMetricSelect(initialMetric);

        if (metricFromQueryParams !== null) {
          setSearchParams("");
        }
      }
    );

    if (!date.length) {
      Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
        setDate(data[0]?.lastUpdatedDate || "");
      });
    }

    if (buttonSectionRef.current) {
      setButtonSectionHeight(buttonSectionRef.current.clientHeight);
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (filters.selectedMetric.code) {
      dispatch(BondValuationSummaryActions.getValuationSummaryTableData());
    }
  }, [filters]);

  useEffect(() => {
    let arr: Data[] = [];

    if (tableData?.bondSummaryData?.length) {
      selectedCompare.map((item: Data) => {
        // @ts-ignore
        let dataItem: Data | NoData = find(
          propEq("indexName", item?.indexName)
        )(tableData?.bondSummaryData);

        if (!dataItem) {
          dataItem = {
            ...item,
            noData: true,
          };
        } else {
          dataItem = {
            ...dataItem,
            action: item.action,
          };
        }

        return (arr = [...arr, dataItem]);
      });
    }
    if (arr.length) {
      setSelectedCompare(arr);
    }
  }, [tableData]);

  const firstNonNullDate = INDECES_WITH_NULLABLE_DATES.includes(
    filters.selectedMetric.code
  )
    ? (tableData.bondSummaryData &&
        tableData.bondSummaryData.findIndex(
          (i: { asOfThreeMonthAgoDate: string | null }) =>
            i.asOfThreeMonthAgoDate !== null
        )) ||
      0
    : 0;

  const headCells: any[] = [
    {
      id: "indexName",
      label: "Index",
      diffBackground: false,
    },
    {
      id: "asOfPriorYearLastUpdate",
      label: `As of ${
        tableData?.bondSummaryData?.length
          ? formatDate(
              tableData?.bondSummaryData[firstNonNullDate]
                ?.asOfPriorYearLastUpdateDate
            )
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "asOfThreeMonthAgo",
      label: `As of ${
        tableData?.bondSummaryData?.length
          ? formatDate(
              tableData.bondSummaryData[firstNonNullDate]?.asOfThreeMonthAgoDate
            )
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "asOfTwoMonthAgo",
      label: `As of ${
        tableData?.bondSummaryData?.length
          ? formatDate(
              tableData.bondSummaryData[firstNonNullDate]?.asOfTwoMonthAgoDate
            )
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "longTermAverage",
      label: "Long Term Average",
      diffBackground: false,
      tooltip: "The average for the metric since its starting year.",
    },
    {
      id: "standardDeviation",
      label: "Standard Deviations",
      diffBackground: false,
      tooltip:
        "A statistical measure of how far the most recent data point is from the long-term average, expressed in standard deviations. Positive for above-average, negative for below. Larger deviations (up or down) indicate rarer extremes.",
    },
    {
      id: "longTermAverageStartingYear",
      label: "Long Term Average Starting Year",
      diffBackground: false,
      tooltip: "The first year the metric became available.",
    },
    {
      id: "action",
      label: "View Chart",
      diffBackground: false,
    },
  ];

  const handleDropMenuSelect = (item: FilterDataItem) => {
    if (item.code === filters.selectedMetric.code) return;
    dispatch(setTableData({}));
    dispatch(setBondFilterMetric(item));
  };
  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setBondFilterMetric(item));
  };

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  return (
    <div>
      <MainContainer>
        <Container>
          <TitleWrapper>
            <Title>Bond Yield, Duration, and Spread Summary Tables</Title>
            <LastUpdateDate date={date} />
          </TitleWrapper>
          <FiltersContainer>
            <DropContainer>
              <DropGroup
                data={metrics}
                label="Specific metric"
                disabled={loading}
                onSelect={handleDropMenuSelect}
                selected={filters.selectedMetric}
                placeholder="Specific metric name goes here"
              />
            </DropContainer>
          </FiltersContainer>
          {loading && (
            <Loader>
              <SmallLoader />
            </Loader>
          )}
          {!loading && (
            <div>
              <TableInfoWrapper ref={buttonSectionRef} compare={compare}>
                <OptionalTooltip text={filters.selectedMetric.description}>
                  <SubTitle>{filters.selectedMetric.name}</SubTitle>
                </OptionalTooltip>

                {!compare && (
                  <CompareButton
                    onClick={compareLines}
                    disabled={selectedCount < 2}
                  >
                    COMPARE {isMobile ? "" : " INDEXES"}
                  </CompareButton>
                )}
                {compare && (
                  <>
                    <CompareButton compare={compare} onClick={compareLines}>
                      back to full list
                    </CompareButton>
                    <ArrowBackContainer onClick={compareLines}>
                      <ArrowBack />
                    </ArrowBackContainer>
                  </>
                )}
              </TableInfoWrapper>
              {tableData?.bondSummaryData?.length && (
                <BondStickyHeaderTable
                  historicalStock={tableRowsData}
                  dataUnitsOfMeasurement={tableData.metricUnitsOfMeasurement}
                  showButton={showButton}
                  compareLines={compare}
                  headCells={headCells}
                  onSelectedCompare={handleSelectedCompare}
                  selectedCompare={selectedCompare}
                  buttonSection={buttonSectionHeight}
                  valuationSummary={true}
                  doNotShowDecimals={ROUND_DECIMALS_TO_ZERO.includes(
                    filters.selectedMetric.code
                  )}
                />
              )}
              <UnderContentInfo>
                <BloombergInfo
                  isBloombergVisible={isBloombergVisible}
                  underTable
                  onChange={toggleBloombergVisible}
                />
                <LogoWrapper>
                  <BottomMfruLogo
                    src={mfruLogoSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.main_page, "_blank");
                    }}
                  />
                  <BottomLogo
                    src={logoBlueSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.assetcamp, "_blank");
                    }}
                  />
                </LogoWrapper>
              </UnderContentInfo>
            </div>
          )}
          <ItemDescriptionWrapper>
            <ItemDescription
              title={filters.selectedMetric.name ?? "About metric name"}
              description={filters.selectedMetric.description ?? ""}
            />
          </ItemDescriptionWrapper>
        </Container>
        {isTablet && <NotifyPopUpTable />}
      </MainContainer>
      {selectedIndex && (
        <BondChartModal
          onClose={closeChart}
          selectedIndex={selectedIndex}
          selectedMetric={filters.selectedMetric}
          doNotShowDecimals={ROUND_DECIMALS_TO_ZERO.includes(
            filters.selectedMetric.code
          )}
        />
      )}
    </div>
  );
};

export default BondValuationSummary;
